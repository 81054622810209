<template>
  <b-overlay :show="processing">
    <b-card body-class="p-0">
      <b-table
        :items="sortedUserByActivityTime"
        :fields="fields"
        striped
        responsive
        class="hours-tracked mb-0"
        selectable
        @row-clicked="row => showDetails(row)"
      >
        <template #head()="data">
          <span class="text-capitalize">{{ data.label }}</span>
        </template>
        <template #cell(name)="row">
          <div
            class="d-flex align-items-center"
            style="width: 68vw;"
          >
            <b-avatar
              :src="getAvatar(row.item.avatar)"
              size="30"
              badge
              :badge-variant="row.item.isOnline ? 'success' : 'secondary'"
              :text="row.item.name.charAt(0)"
              class="mr-1"
              variant="light-primary"
            />
            <div>
              <span>{{ row.item.name }}</span>

              <feather-icon
                v-if="row.detailsShowing"
                size="20"
                icon="ChevronUpIcon"
              />
              <feather-icon
                v-else
                size="20"
                icon="ChevronDownIcon"
              />
            </div>
          </div>
        </template>
        <template #cell(dailyActivities)="row">
          <b>{{ CONVERT_HM(getTotalTimes(row.item)) }}</b>
        </template>
        <template #row-details="row">
          <b-card
            v-if="row.item.dailyActivities.data.length"
            body-class="m-0 p-0 p-md-1"
          >
            <hours-tracked-chart
              v-if="showChart"
              :activities="row.item.dailyActivities.data"
            />
            <h5 class="my-1">
              {{ $t('Details') }}
            </h5>
            <b-row
              v-for="(dailyActivity,index) of getDurationCalculation(row.item).activities"
              :key="index"
              class="p-1 border"
            >
              <b-col
                sm="3"
                class="text-link"
                @click="SHOW_RECORD(dailyActivity.date, row.item.uuid)"
              >
                {{ dailyActivity.date }}
              </b-col>
              <b-col sm="3">
                {{ CONVERT_HM(dailyActivity.duration) }}
              </b-col>
              <b-col sm="6">
                <b-progress
                  style="border-radius: 0;"
                  class="d-flex"
                  height="1.3rem"
                  :max="100"
                  :set="percentInfo = getTimeDuration(dailyActivity, getDurationCalculation(row.item).maxDuration)"
                >
                  <b-progress-bar
                    v-b-tooltip.hover.top
                    :title="((1 - dailyActivity.idlePercent) > 0.3 && dailyActivity.duration > 1300) ? 'Working' : `Working - ${ Math.round((1 - dailyActivity.idlePercent) * 100,0) } %`"
                    :value="percentInfo.activePercent"
                    variant="success"
                    style="border-radius: 0;"
                  >
                    <span
                      v-if="((1 - dailyActivity.idlePercent) > 0.3 && dailyActivity.duration > 1300) || dailyActivity.duration > 3600"
                    >
                      {{ Math.round((1 - dailyActivity.idlePercent) * 100,0) }} %
                    </span>
                  </b-progress-bar>
                  <b-progress-bar
                    v-b-tooltip.hover.top
                    :title="((dailyActivity.idlePercent) > 0.3 && dailyActivity.duration > 1300) ? 'Idle' : `Idle - ${ Math.round((dailyActivity.idlePercent) * 100,0) } %`"
                    :value="percentInfo.idlePercent"
                    class="bg-idle"
                    style="border-radius: 0;"
                  >
                    <span
                      v-if="(dailyActivity.idlePercent > 0.3 && dailyActivity.duration > 1300) || dailyActivity.duration > 3600"
                      class="text-primary"
                    >
                      {{ Math.round(dailyActivity.idlePercent * 100,0) }} %
                    </span>
                  </b-progress-bar>
                </b-progress>
              </b-col>
            </b-row>
          </b-card>
          <b-card
            v-else
            body-class="p-0"
          >
            <p class="text-danger p-1">
              {{ $t('reports.not-tracked') }}
            </p>
          </b-card>
        </template>
      </b-table>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BCard, BProgress, BProgressBar, VBTooltip,
  BCol, VBToggle, BTable, BAvatar, BOverlay,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import { setComponentReady } from '@/views/tour/tour'
import HoursTrackedChart from './HoursTrackedChart.vue'

export default {
  components: {
    BCard,
    BProgress,
    BProgressBar,
    BCol,
    BTable,
    BAvatar,
    BOverlay,
    HoursTrackedChart,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  props: {
    showChart: {
      type: Boolean,
      default: () => true,
    },
    startDate: {
      type: [Object, Date, String],
      default: null,
    },
    endDate: {
      type: [Object, Date, String],
      default: null,
    },
    userId: {
      type: [Object, String, Array],
      default: null,
    },
    timezone: {
      type: [Object, String],
      default: null,
    },
  },

  data() {
    return {
      processing: false,
      maxDuration: 0,
      users: [],
      fields: [
        { key: 'name', label: this.$t('Name') },
        { key: 'dailyActivities', label: this.$t('Total Worked') },
      ],
    }
  },
  computed: {
    sortedUserByActivityTime() {
      const calculatedActivity = this.users.map(user => ({
        ...user,
        total: user.dailyActivities.data.reduce((p, c) => p + c.duration, 0),
      }))
      calculatedActivity.sort((a, b) => b.total - a.total)
      return calculatedActivity
    },
  },
  watch: {
    startDate: {
      handler() {
        this.getDailyActivityRecords()
      },
      immediate: true,
    },
    endDate: {
      handler() {
        this.getDailyActivityRecords()
      },
      immediate: true,
    },
    userId: {
      handler() {
        this.getDailyActivityRecords()
      },
      immediate: true,
    },
    timezone: {
      handler() {
        this.getDailyActivityRecords()
      },
      immediate: true,
    },
  },
  methods: {
    showDetails(row) {
      // eslint-disable-next-line no-underscore-dangle
      if (row._showDetails) {
        this.$set(row, '_showDetails', false)
      } else {
        this.sortedUserByActivityTime.forEach(item => {
          this.$set(item, '_showDetails', false)
        })

        this.$nextTick(() => {
          this.$set(row, '_showDetails', true)
        })
      }
    },
    getDailyActivityRecords() {
      this.processing = true
      const startTime = `${this.startDate}`
      const endTime = `${this.endDate}`
      useApollo.activities.getAllDailyActivities({
        startTime,
        endTime,
        projectId: this.$store.state.project.selectedProject,
        userId: this.userId,
        first: -1,
      }).then(response => {
        this.users = response.data.users.data
        setComponentReady()
      }).catch(error => {
        this.showErrorMessage(error)
        console.error(error)
      }).finally(() => { this.processing = false })
    },
    getTimeDuration(dailyActivity, maxDuration) {
      const totalPercent = (dailyActivity.duration / maxDuration) * 100
      const idlePercent = dailyActivity.idlePercent * totalPercent
      return {
        activePercent: totalPercent - idlePercent,
        idlePercent,
      }
    },
    getDurationCalculation(item) {
      const activities = item.dailyActivities.data
      const maxDuration = Math.max(...activities.map(activity => activity.duration))
      return {
        maxDuration,
        activities,
      }
    },
    getTotalTimes(item) {
      const activities = item.dailyActivities.data
      return activities.reduce((p, c) => p + c.duration, 0)
    },
  },
}
</script>
<style lang="scss" scoped>
  .bg-idle {
    background-color: #c1c7cf !important;
  }
  .progress {
    background-color: transparent !important;
  }
</style>

<style lang="scss">
@media screen and (max-width: 768px) {
  .hours-tracked {
    td {
      padding: 10px 4px;
    }
  }
}
</style>
