<template>
  <div>
    <vue-apex-charts
      :height="350"
      :width="activities.length > 10 ? 32 * activities.length : '100%'"
      :options="{
        ...chartOptions,
        xaxis: {
          categories: dates
        },
        yaxis: {
          labels: {
            formatter(val) {
              return CONVERT_HM(val)
            },
          },
          title: {
            text: $t('Time'),
          },
        },
        dataLabels: {
          enabled: false,
          formatter(val) {
            return CONVERT_HM(val)
          }
        },
      }"
      :series="[{
        name: $t('reports.total-hours'),
        data: seconds,
      }]"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    activities: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartOptions: {
        title: {
          text: this.$t('Hours Tracked'),
          align: 'left',
        },
        chart: {
          type: 'area',
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100],
          },
        },
        markers: {
          size: 5,
        },
      },
    }
  },
  computed: {
    dates() {
      const result = this.activities.map(activity => moment(activity.date))
      const sortedArray = result.sort((a, b) => a.diff(b))
      const minDate = sortedArray[0]
      const maxDate = sortedArray[sortedArray.length - 1]
      const nDays = maxDate.diff(minDate, 'days') + 1
      const days = [...Array(nDays).keys()]
        .map(i => minDate.clone().add(i, 'd'))
      return days.map(item => item.format('DD MMM'))
    },
    seconds() {
      const mappedDurations = []
      const result = this.activities.map(activity => {
        mappedDurations[moment(activity.date).format('DD-MM-YYYY')] = activity.duration
        return {
          duration: activity.duration, date: moment(activity.date),
        }
      })
      const sortedArray = result.sort((a, b) => a.date.diff(b))
      const minDate = sortedArray[0]
      const maxDate = sortedArray[sortedArray.length - 1]
      const nDays = maxDate.date.diff(minDate.date, 'days') + 1
      const days = [...Array(nDays).keys()]
        .map(i => ({ date: minDate.date.clone().add(i, 'd'), duration: mappedDurations[minDate.date.clone().add(i, 'd').format('DD-MM-YYYY')] ?? 0 }))

      return days.map(activity => activity.duration)
    },
  },
}
</script>
